<template>
  <b-card>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="rows"
      style-class="vgt-table striped"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        dropdownAllowAll: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'edit-tl-coach', params: { id: props.row.id } }">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteTLCoach(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Church',
          field: 'church_regional_id',
        },
        {
          label: 'IFrame URL',
          field: 'iframe_url',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    // Get the data
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/tlcoach?token=${process.env.VUE_APP_API_TOKEN}`)
      .then(response => {
        this.isLoading = false
        this.rows = response.data.result
    })
  },
  methods: {
    deleteTLCoach(id) {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Check if the user has access
      if (!this.$can('delete', 'tlcoach')) {
        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'You are not authorized to do this action.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          // Show loading
          this.isLoading = true

          if (result.value) {
            // Delete
            this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/tlcoach/${id}?token=${process.env.VUE_APP_API_TOKEN}`)
              .then(() => {
                // Hide loading
                this.isLoading = false

                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'The iFrame data has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })

                const tlCoachIndex = this.rows.findIndex(tlCoach => tlCoach.id === id)
                this.rows.splice(tlCoachIndex, 1)
              })
          } else {
            // Hide loading
            this.isLoading = false
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>